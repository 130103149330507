import { Button, Col, Form, Input, Modal, Row, Switch, Typography } from "antd";
import React, { useContext, useState } from "react";
import { Severty, ShowToast } from "../helper/toast";
import useRequest from "../hooks/useRequest";
import { AuthContext } from "../context/AuthContext";
import RegisterModal from "./RegisterModal";
const { Title } = Typography;

const LoginModal = ({ open, setOpen }) => {
  const [register, setRegister] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleClose = () => {
    setOpen(false);
    form.resetFields();
  };
  const [form] = Form.useForm();
  const { request } = useRequest();
  const { setUserProfile } = useContext(AuthContext);

  const onSubmit = (values) => {
    const { email, password } = values;

    if (!email)
      return ShowToast("Please enter email to sign in", Severty.ERROR);

    const payload = { password };
    if (!email) return ShowToast("Please enter valid email ", Severty.ERROR);

    setLoading(true);
    payload.email = email;
    request({
      url: "https://jewellskart.com/apanel/api/login",
      //  url: "auth/login",
      method: "POST",
      data: payload,
      onSuccess: (data) => {
        setLoading(false);
        console.log(data, "data.message");
        if (data.status) {
          console.log(data);
          localStorage.setItem("token", data.data.api_token);
          localStorage.setItem("userProfile", JSON.stringify(data.data));
          ShowToast(data.message, Severty.SUCCESS);
          setUserProfile(data.data.user);
          window.location.reload();
        } else {
          ShowToast(data.errors, Severty.ERROR);
        }
      },
      onError: (error) => {
        ShowToast(error?.response?.data?.message, Severty.ERROR);
        setLoading(false);
      },
    });
  };
  return (
    <div>
      <Modal
        className="sign-modal"
        open={open}
        footer={null}
        onCancel={handleClose}
      >
        <Row justify="space-around">
          <Col xs={{ span: 24 }} lg={{ span: 24 }} md={{ span: 24 }}>
            <div className="signup-form">
              <div className="modal-tital">
                <Title className="mb-15">Sign In</Title>
                <Title className="font-regular text-muted" level={5}>
                  Enter your email and password to sign in
                </Title>
              </div>
              <Form
                form={form}
                layout="vertical"
                className="row-col"
                onFinish={onSubmit}
              >
                <Form.Item
                  className="username"
                  label="Email Address"
                  name="email"
                  rules={[
                    {
                      type: "email",
                      message: "Please enter a valid email address!",
                    },
                    {
                      max: 255,
                      message: "Email address not more then 255 characters!",
                    },
                    {
                      required: true,
                      message: "Please enter your email!",
                    },
                  ]}
                >
                  <Input
                    className="sign-innn"
                    placeholder="Enter Email Address"
                  />
                </Form.Item>

                <Form.Item
                  className="password"
                  label="Password"
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "Please enter your password!",
                    },
                  ]}
                >
                  <Input.Password
                    className="sign-innn"
                    autoComplete="off"
                    placeholder="Enter Password"
                  />
                </Form.Item>
                <div className="forgot-pass">
                  <Form.Item
                    name="remember"
                    className="aligin-center"
                    valuePropName="checked"
                  >
                    <Switch className="" />
                    Remember me
                  </Form.Item>
                  <Form.Item
                    name="remember"
                    className="aligin-center"
                    valuePropName="checked"
                  >
                    <a
                      className="forgate-btn"
                      onClick={() => {
                        setRegister(true);
                        handleClose();
                      }}
                    >
                      Don't have account
                    </a>
                  </Form.Item>
                </div>

                <Form.Item>
                  <Button className="sign-btn" type="primary" htmlType="submit">
                    {" "}
                    SIGN IN{" "}
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </Col>
        </Row>
      </Modal>
      <RegisterModal open={register} setOpen={setRegister} />
    </div>
  );
};

export default LoginModal;
