import React from "react";
const PrivacyIndex = (initialWishlistData) => {
  return (
    <>
      <section className="howit-work space-cls">
        <div className="container">
          <div className="inner-title">
            <h3 className="heading-type2">Privacy Policy</h3>
          </div>
          <div className="about-page">
            <div className="row">
              <div className="col-md-12">
                <div className="about-main-cont">
                  <div className="about-cont">
                    <p>
                      At Ayati Jewels LLP, we take your privacy seriously. This
                      Privacy Policy outlines how we collect, use, disclose, and
                      safeguard your personal information. By using our
                      platform, you consent to the practices described in this
                      policy.
                    </p>
                    <h4>Information We Collect</h4>
                    <p>
                      We may collect personal information from users, including
                      but not limited to:
                    </p>
                    <ul>
                      <li>
                        Name, email address, contact information, and business
                        details during the registration process.
                      </li>
                      <li>
                        Transaction details, including payment information, to
                        facilitate secure transactions.
                      </li>
                      <li>
                        Communication records, such as emails and messages
                        exchanged on the platform.
                      </li>
                    </ul>
                    <h4>How We Use Your Information</h4>
                    <ul>
                      <li>
                        Personal information is used to create and maintain your
                        account, facilitate transactions, and provide customer
                        support.
                      </li>
                      <li>
                        We may use your email address to send you updates,
                        newsletters, or promotional offers related to our
                        platform. You can opt-out of these communications at any
                        time.
                      </li>
                    </ul>
                    <h4>Data Security</h4>
                    <p>
                      We implement robust security measures to protect your
                      personal information from unauthorized access, disclosure,
                      alteration, or destruction. However, no data transmission
                      over the internet can be guaranteed as 100% secure. Please
                      take necessary precautions while using our platform.
                    </p>
                    <h4>Disclosure of Information</h4>
                    <p>
                      We may share your personal information with trusted
                      third-party service providers to facilitate transactions,
                      improve our services, or comply with legal obligations. We
                      do not sell or rent your personal information to third
                      parties for marketing purposes.
                    </p>
                    <h4>Your Choices</h4>
                    <p>
                      You have the right to access, update, or delete your
                      personal information. You can manage your communication
                      preferences by adjusting your account settings or
                      unsubscribing from promotional emails.
                    </p>
                    <h4>Updates to Privacy Policy</h4>
                    <p>
                      This Privacy Policy may be updated periodically to reflect
                      changes in our practices or applicable laws. We will
                      notify users of any significant updates through our
                      platform or by email.
                    </p>
                    <h4>Contact Us</h4>
                    <p>
                      For any questions or concerns regarding our Privacy
                      Policy, please contact us.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PrivacyIndex;
