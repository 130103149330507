import React from "react";

const Index = (initialWishlistData) => {
  return (
    <>
      <section className="howit-work space-cls">
        <div className="container">
          <div className="inner-title">
            <h3 className="heading-type2">About Us</h3>
            {/* <p>Lorem ipsum dolor sit amet, consectetur adipis elit</p>  */}
          </div>
          <div className="about-page">
            <div className="row">
              <div className="col-md-12">
                <div className="about-main-cont">
                  <div className="about-cont">
                    <h4>
                      Welcome to Jewellskart.com - Revolutionizing Jewellery
                      Trade for Manufacturers and Retailers!
                    </h4>
                    <p>
                      At Jewellskart.com, we are on a mission to simplify and
                      elevate the way jewellers trade. We are the bridge that
                      connects manufacturers and retailers, empowering them to
                      engage in seamless and efficient business transactions
                      like never before.
                    </p>
                    <h4>Our Vision</h4>
                    <p>
                      Our vision is to be the catalyst for positive change in
                      the jewellery industry. We envision a future where
                      manufacturers and retailers can effortlessly connect and
                      collaborate, fostering strong partnerships that drive
                      mutual growth and success.
                    </p>
                    <h4>Why Choose Jewellskart.com</h4>
                    <p>
                      <b>Effortless Connectivity</b> We bring manufacturers and
                      retailers together on a single platform, eliminating the
                      complexities of traditional trade and enabling instant
                      connectivity and communication.
                    </p>
                    <p>
                      <b>Vast Supplier Network</b> Our curated network of
                      trusted manufacturers offers a wide and diverse selection
                      of jewellery, ensuring retailers have access to the latest
                      designs and trends to delight their customers.
                    </p>
                    <p>
                      <b>Streamlined Transactions</b> With our user-friendly
                      interface and innovative tools, all stages of the trade
                      process - from sourcing to purchasing - are simplified,
                      enhancing efficiency and saving valuable time.
                    </p>
                    <p>
                      <b>Verified and Trusted Partners</b> We meticulously vet
                      all our manufacturer and retailer partners, instilling
                      confidence and reliability in every trade transaction.
                    </p>
                    <p>
                      <b>Global Reach</b> Our platform transcends geographical
                      boundaries, opening up new opportunities for manufacturers
                      and retailers to expand their reach and explore
                      international markets.
                    </p>
                    <p>
                      <b>Quality Checked Products</b> Quality is our top
                      priority. Every product listed on Jewellskart.com
                      undergoes rigorous quality checks to ensure that only the
                      finest and most exquisite jewellery reaches your doorstep.
                      Our commitment to quality reinforces your reputation as a
                      trusted retailer.
                    </p>
                    <p>
                      <b>Easy Returns</b> We understand that returns can be a
                      part of any business. Our platform streamlines the returns
                      process, making it hassle-free for both manufacturers and
                      retailers. Transparent return policies foster trust and
                      build lasting partnerships.
                    </p>
                    <p>
                      <b>Secured Payments</b> We understand the paramount
                      importance of secure transactions in the jewellery
                      industry. With state-of-the-art encryption and secure
                      payment gateways, you can conduct your trade transactions
                      with confidence, knowing that your financial information
                      is protected.
                    </p>
                    <p>
                      <b>Customize Orders</b> We believe in the power of
                      personalized offerings. Manufacturers can seamlessly
                      collaborate with retailers to fulfill customized orders,
                      catering to the unique tastes and preferences of their
                      customers. Let creativity and craftsmanship flourish on
                      our platform.
                    </p>
                    <h4>Our Commitment</h4>
                    <p>
                      We are committed to empowering jewellery businesses of all
                      sizes to thrive in the digital era. Our platform is
                      designed with your needs in mind, providing a secure and
                      intuitive environment that fosters successful partnerships
                      and flourishing businesses.
                    </p>
                    <h4>Join Us on This Transformative Journey</h4>
                    <p>
                      Whether you are a manufacturer seeking to showcase your
                      craftsmanship to a global audience or a retailer looking
                      for the finest jewellery offerings, Jewellskart.com is the
                      ultimate destination for your trade needs.
                    </p>
                    <p>
                      Our platform is meticulously designed to meet the diverse
                      needs of jewellery manufacturers and retailers. We are
                      continuously innovating and upgrading our services to
                      ensure that you enjoy a seamless and rewarding trading
                      experience.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Index;
