import { Button, Row, Col, Space, Table } from "antd";

import React, { useContext, useEffect, useState } from "react";

import { Container } from "react-bootstrap";

import {
  DeleteOutlined,
  StarOutlined,
  HeartOutlined,
  StarFilled,
} from "@ant-design/icons";

import OwlCarousel from "react-owl-carousel3";

import Product01 from "../assets/images/product01.png";
import useRequest from "../hooks/useRequest";
import { Severty, ShowToast } from "../helper/toast";
import LoginModal from "../components/LoginModal";
import { AuthContext } from "../context/AuthContext";
import { useNavigate } from "react-router";

const AddIndex = () => {
  const [loginModal, setLoginModal] = useState(false);
  const [productData, setProductData] = useState([]);
  const [subTotal, setSubTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const { request } = useRequest();
  const { userProfile } = useContext(AuthContext);
  const navigate = useNavigate();
  const [cartData, setCartData] = useState([]);
  const token = localStorage.getItem("token")
    ? localStorage.getItem("token")
    : "";

  const columns = [
    {
      title: "Product",
      dataIndex: "name",
      key: "name",
      render: (text) => <a>{text}</a>,
    },
    {
      title: "Price",
      dataIndex: "Price",
      key: "Price",
    },
    {
      title: "Quantity",
      dataIndex: "Quantity",
      key: "Quantity",
    },
    {
      title: "Subtotal",
      dataIndex: "Subtotal",
      key: "Subtotal",
    },
    {
      title: "",
      key: "action",
      render: (_, record) => (
        <Space size="middle" onClick={() => removeToCart(record.key)}>
          {console.log(record)}
          <a>
            <DeleteOutlined />
          </a>
        </Space>
      ),
    },
  ];

  useEffect(() => {
    if (!token) return;
    request({
      url: `/shopping/cart-list`,
      method: `GET`,
      onSuccess: (data) => {
        setLoading(false);
        if (data.status) {
          let Subtotal = 0;
          setProductData(
            data.data.data.map((item) => {
              Subtotal = Subtotal + item.piroi_price * item.quantity;
              return {
                key: item.product_id,
                name: (
                  <img
                    width={50}
                    alt={item.product_name}
                    src={`https://jewellskart.com/apanel/public/upload/${item.neck_piece_category_image}`}
                  />
                ),
                Price: item.piroi_price,
                Quantity: item.quantity,
                Subtotal: item.piroi_price * item.quantity,
              };
            })
          );
          setSubTotal(Subtotal);
          // ShowToast(data.message, Severty.SUCCESS);
        } else {
          // ShowToast(data.message, Severty.ERROR);
        }
      },
      onError: (error) => {
        ShowToast(error.response.data.message, Severty.ERROR);

        setLoading(false);
      },
    });
  }, [token]);

  const handleSaveToCart = () => {
    navigate("/");
  };

  const handleCheckOut = () => {
    if (userProfile.vType === "guest") {
      setLoginModal(true);
    } else {
      setLoading(true);
      request({
        url: `/shopping/cart/checkout`,
        method: `POST`,
        onSuccess: (data) => {
          setLoading(false);
          if (data.status) {
            ShowToast(data.message, Severty.SUCCESS);
            productData.forEach((item) => {
              removeToCart(item.key, setProductData);
            });
            setCartData([]);
            setProductData([]);
            navigate("/");
          } else {
            ShowToast(data.message, Severty.ERROR);
          }
        },
        onError: (error) => {
          ShowToast(
            error.response?.data?.message || "An error occurred",
            Severty.ERROR
          );
          setLoading(false);
        },
      });
    }
  };

  //cart item remove

  const removeToCart = (id) => {
    request({
      url: `/shopping/cart?productId=${id}`,
      method: `POST`,
      onSuccess: (data) => {
        setLoading(false);
        if (data.status) {
          let Subtotal = 0;
          setProductData((prev) =>
            prev.filter((item) => {
              if (item.key !== id) {
                Subtotal = Subtotal + item.Subtotal;
              }
              return item.key !== id;
            })
          );
          setSubTotal(Subtotal);
          // ShowToast(data.message, Severty.SUCCESS);
        } else {
          ShowToast(data.message, Severty.ERROR);
        }
      },
      onError: (error) => {
        // ShowToast(error.response.data.message, Severty.ERROR);

        setLoading(false);
      },
    });
  };

  const responsiveSetting = {
    0: {
      items: 1,
    },
    300: {
      items: 1,
    },
    400: {
      items: 2,
    },
    600: {
      items: 2,
    },
    800: {
      items: 3,
    },
    1200: {
      items: 4,
    },
    1400: {
      items: 4,
    },
  };
  return (
    <>
      <div className="cart-ex-section">
        <Container>
          <Row>
            <Col span={24} md={24}>
              <div className="sl-detail-main">
                <h4>Shopping Cart</h4>
                <p>
                  Home <sapn>Shopping Cart</sapn>{" "}
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="cart-section">
        <Container>
          <Row gutter={24}>
            <Col span={24} md={16}>
              <div className="cart-table">
                <Table
                  rowKey="id"
                  columns={columns}
                  bordered
                  dataSource={productData}
                  pagination={false}
                />
                <div className="actions">
                  <div class="bottom-cart">
                    <button
                      onClick={handleSaveToCart}
                      type="submit"
                      name="update_cart"
                      class="button"
                      value="Update cart"
                    >
                      Continue Shopping
                    </button>
                  </div>
                </div>
              </div>
            </Col>
            <Col span={24} md={8}>
              <div className="cart-item-total">
                <div className="header-cart-total">
                  <h6>Cart totals</h6>
                </div>
                <div className="body-cart-total">
                  <div>
                    <div class="cart-subtotal">
                      <div class="title">Subtotal</div>
                      <div>
                        <span>${subTotal}</span>
                      </div>
                    </div>

                    <div class="order-total">
                      <div class="title">Total</div>
                      <div>
                        <span>${subTotal}</span>
                      </div>
                    </div>
                  </div>
                  <div class="proceed-to-checkout" onClick={handleCheckOut}>
                    <span class="checkout-button button">
                      Proceed to checkout
                    </span>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="trending-section">
        <div className="headding">
          <h4>Cross Sell Products</h4>
        </div>
        <Container>
          <Row>
            <Col span={24}>
              <OwlCarousel
                className="owl-theme  category-section"
                items={5}
                loop
                margin={26}
                nav
                dots={false}
                responsive={responsiveSetting}
              >
                <div class="item">
                  <div className="products-items-list">
                    <div className="pruducts-items">
                      <img src={Product01} />
                      <div className="bg-icon">
                        <HeartOutlined />
                      </div>
                    </div>
                    <div className="product-detail">
                      <h6>Earring Necklace Jewellery Chain Gemstone</h6>
                      <p className="price-ff">
                        <span>Rs 220000</span>Rs 220000
                      </p>
                      <div className="ratting-star">
                        <span>
                          <StarFilled />
                        </span>
                        <span>
                          <StarFilled />
                        </span>
                        <span>
                          <StarFilled />
                        </span>
                        <span>
                          <StarFilled />
                        </span>
                        <span>
                          <StarOutlined />
                        </span>
                      </div>
                      <Button className="cart-btn">Add to cart</Button>
                    </div>
                  </div>
                </div>
              </OwlCarousel>
            </Col>
          </Row>
        </Container>
      </div>
      <LoginModal open={loginModal} setOpen={setLoginModal} />
      <div className="mini-footer">
        <p>@ 2023 Jewelery company limited. All right reserved. </p>
      </div>
    </>
  );
};

export default AddIndex;
