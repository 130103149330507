import { Spin } from 'antd';
// import { LoadingOutlined } from '@ant-design/icons';
import { FadeLoader } from "react-spinners";
import './loader.css'
const antIcon = (
    <FadeLoader
        style={{
            fontSize: 42,
            color: `#36d7b7`,
            display:"flex",
           
        }}
        spin
    />
);

const Loader = () => {
    return (
        <div className="spin_loader" >
           
            <Spin indicator={antIcon} />
        </div>
    )
}

export default Loader