import React, { useContext, useEffect, useState } from "react";
import { Button, Tabs, Input, Form, Modal, Table } from "antd";

import { Container } from "react-bootstrap";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import ProfileIcon from "../assets/images/icon/profile.png";
import { AuthContext } from "../context/AuthContext";
import useRequest from "../hooks/useRequest";
import { Severty, ShowToast } from "../helper/toast";

const AccounIndex = (initialWishlistData) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { request } = useRequest();
  const [loading, setLoading] = useState(false);
  const { logout, setUserProfile, userProfile } = useContext(AuthContext);
  const [form] = Form.useForm();
  const [orderData, setOrderData] = useState([]);
  const { TabPane } = Tabs;
  const navigate = useNavigate();
  const [wishlistData, setWishlistData] = useState(initialWishlistData);
  const [tabPosition, setTabPosition] = useState("left");

  const handleLogout = () => {
    logout();
    navigate("/");
  };

  const handleFormSubmit = (values) => {
    console.log("Updated values:", values);
    setUserProfile(values);
    localStorage.setItem("user", JSON.stringify(values));
  };

  useEffect(() => {
    form.setFieldsValue(userProfile); // Set initial form values from userProfile
  }, [userProfile, form]);

  const columns = [
    {
      title: "Cart ID",
      dataIndex: "cart_id",
      key: "cart_id",
    },
    {
      title: "User ID",
      dataIndex: "user_id",
      key: "user_id",
    },
    {
      title: "Order ID",
      dataIndex: "order_id",
      key: "order_id",
    },
    {
      title: "Total Products",
      dataIndex: "total_product",
      key: "total_product",
    },
    {
      title: "Total Amount",
      dataIndex: "total_amount",
      key: "total_amount",
    },
    {
      title: "Created At",
      dataIndex: "created_at",
      key: "created_at",
      render: (created_at) => (
        <>
          <div>{moment(created_at).format("MMMM DD, YYYY")}</div>
          <div className="time-text">
            {moment(created_at).format("hh:mm A")}
          </div>
        </>
      ),
    },
  ];

  const column = [
    {
      title: "Product Name",
      dataIndex: "product_name",
      key: "product_name",
    },
    {
      title: "Product Price",
      dataIndex: "piroi_price",
      key: "piroi_price",
      render: (price) => `Rs. ${price}`,
    },
    {
      title: "Created At",
      dataIndex: "created_at",
      key: "created_at",
      render: (created_at) => (
        <>
          <div>{moment(created_at).format("MMMM DD, YYYY")}</div>
          <div className="time-text">
            {moment(created_at).format("hh:mm A")}
          </div>
        </>
      ),
    },
  ];

  useEffect(() => {
    request({
      url: "/shopping/order-list",
      method: "GET",
      onSuccess: (data) => {
        console.log(data.data.data);
        setOrderData(data.data.data);
      },
      onError: (error) => {
        console.log(error);
        ShowToast(error, Severty.ERROR);
      },
    });
  }, []);

  useEffect(() => {
    request({
      url: "/shopping/favorite-list",
      method: "GET",
      onSuccess: (data) => {
        console.log(data.data.data);
        setWishlistData(data.data.data);
      },
      onError: (error) => {
        console.log(error);
        ShowToast(error, Severty.ERROR);
      },
    });
  }, []);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
    navigate("/");
    handleLogout();
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  return (
    <>
      <div className=" ">
        <Container>
          <div className="main-outer">
            <div className="my-acc-section">
              <Tabs
                defaultActiveKey="1"
                className="message-min"
                tabPosition={tabPosition}
              >
                <TabPane
                  tab={
                    <span className="side-nav">
                      <img src={ProfileIcon} alt="icon" />
                      My Account
                    </span>
                  }
                  key="1"
                  className="budget-main"
                >
                  <div className="account-main-box">
                    <Form
                      form={form}
                      name="userProfile"
                      layout="vertical"
                      onFinish={handleFormSubmit}
                    >
                      <Form.Item
                        label="Name"
                        name="name"
                        rules={[
                          {
                            required: true,
                            message: "Please input your name!",
                          },
                        ]}
                      >
                        <Input className="sign-innn" />
                      </Form.Item>
                      <Form.Item
                        label="Email"
                        name="email"
                        rules={[
                          {
                            required: true,
                            message: "Please input your email!",
                            type: "email",
                          },
                        ]}
                      >
                        <Input className="sign-innn" />
                      </Form.Item>
                      <Form.Item
                        label="Mobile Number"
                        name="mobile_number"
                        rules={[
                          {
                            required: true,
                            message: "Please input your mobile number!",
                          },
                        ]}
                      >
                        <Input className="sign-innn" />
                      </Form.Item>
                      <Form.Item>
                        <Button type="primary" htmlType="submit">
                          Update Profile
                        </Button>
                      </Form.Item>
                    </Form>
                  </div>
                </TabPane>

                <TabPane
                  tab={
                    <span className="side-nav">
                      <img src={ProfileIcon} alt="icon" />
                      My Orders
                    </span>
                  }
                  key="3"
                  className="budget-main"
                >
                  <div className="my-pro-page">
                    <Table
                      columns={columns}
                      dataSource={orderData}
                      pagination={false}
                      rowKey={(record) => record.id}
                    />
                  </div>
                </TabPane>
                <TabPane
                  tab={
                    <span className="side-nav">
                      <img src={ProfileIcon} alt="icon" />
                      WishList
                    </span>
                  }
                  key="4"
                  className="budget-main"
                >
                  <div className="my-pro-page">
                    <Table
                      dataSource={wishlistData}
                      columns={column}
                      loading={loading}
                      rowKey={(record) => record.id} // Ensure to set a unique key for each row
                      pagination={false} // Disable pagination if you don't need it
                    />
                  </div>
                </TabPane>

                <TabPane
                  tab={
                    <span className="side-nav" onClick={showModal}>
                      <img src={ProfileIcon} alt="icon" />
                      Logout
                    </span>
                  }
                  key="5"
                  className="budget-main"
                ></TabPane>
              </Tabs>
            </div>
          </div>
        </Container>
      </div>

      <Modal
        className="sign-modal"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Yes"
        cancelText="No"
      >
        {userProfile && (
          <div className="user-info">
            <p>Name: {userProfile.name}</p>
            <p>Email: {userProfile.email}</p>
          </div>
        )}

        <p>Are you sure you want to logout ?</p>
      </Modal>
    </>
  );
};

export default AccounIndex;
