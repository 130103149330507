import React from "react";

const Index = (initialWishlistData) => {
  return (
    <>
      <section className="howit-work space-cls">
        <div className="container">
          <div className="inner-title">
            <h3 className="heading-type2">Terms & Conditions</h3>
          </div>
          <div className="about-page">
            <div className="row">
              <div className="col-md-12">
                <div className="about-main-cont">
                  <div className="about-cont">
                    <p>
                      Welcome to Jewellskart.com. By accessing and using this
                      website, you agree to comply with and be bound by the
                      following terms and conditions. Please read them carefully
                      before proceeding with any transactions on our platform.
                    </p>
                    <h4>Registration and Account</h4>
                    <p>
                      To use our platform, you must be 18 years of age or older
                      and have the legal authority to enter into binding
                      contracts. By creating an account, you confirm that you
                      meet these requirements.
                    </p>
                    <p>
                      You are responsible for maintaining the confidentiality of
                      your account credentials, and you agree to accept
                      responsibility for all activities that occur under your
                      account.
                    </p>
                    <h4>Product Listings and Information</h4>
                    <p>
                      While we strive to provide accurate and up-to-date product
                      information, we do not warrant or guarantee the accuracy,
                      completeness, or reliability of any product listings or
                      descriptions on our platform.
                    </p>
                    <p>
                      Manufacturers are responsible for ensuring that the
                      products listed on Jewellskart.com comply with all
                      applicable laws and regulations, including those related
                      to intellectual property rights.
                    </p>
                    <h4>Secure Payments</h4>
                    <p>
                      We utilize secure payment gateways to facilitate
                      transactions on our platform. All payment details provided
                      by users are encrypted and protected.
                    </p>
                    <p>
                      Jewellskart.com is not responsible for any financial
                      losses or damages resulting from unauthorized access to
                      your payment information.
                    </p>
                    <h4>Custom Orders</h4>
                    <p>
                      Customized orders must be clearly communicated and agreed
                      upon by both the manufacturer and retailer.
                    </p>
                    <p>
                      Manufacturers must accurately fulfill the specifications
                      and requirements of custom orders, and retailers must
                      provide clear instructions for customization.
                    </p>
                    <h4>Quality Assurance and Returns</h4>
                    <p>
                      Manufacturers are responsible for ensuring the quality and
                      authenticity of their products.
                    </p>
                    <p>
                      Retailers have the right to return products that do not
                      meet the agreed-upon specifications or are damaged,
                      subject to the agreed-upon return policies.
                    </p>
                    <h4>Intellectual Property</h4>
                    <p>
                      The content, logos, and trademarks on Jewellskart.com are
                      the intellectual property of the platform and its
                      affiliates. Unauthorized use of any of these materials is
                      strictly prohibited.
                    </p>
                    <h4>Termination</h4>
                    <p>
                      Jewellskart.com reserves the right to terminate or suspend
                      any user account at its discretion for any reason,
                      including violation of these terms and conditions.
                    </p>
                    <h4>Limitation of Liability</h4>
                    <p>
                      Jewellskart.com shall not be liable for any direct,
                      indirect, incidental, special, or consequential damages
                      resulting from the use or inability to use our platform or
                      the products listed on it.
                    </p>
                    <h4>Governing Law</h4>
                    <p>
                      These terms and conditions shall be governed by and
                      construed in accordance with the laws of Jaipur
                      jurisdiction.
                    </p>
                    <p>
                      By using Jewellskart.com, you acknowledge that you have
                      read, understood, and agreed to these terms and
                      conditions. These terms may be updated from time to time,
                      and it is your responsibility to review them periodically.
                      If you do not agree with any part of these terms, please
                      do not use our platform.
                    </p>
                    <p>
                      For any questions or concerns about our terms and
                      conditions, please contact us.
                    </p>
                    <p>
                      Please ensure that you tailor the terms and conditions to
                      comply with the laws and regulations of your specific
                      region and industry. The document should be clear and
                      comprehensive to protect the interests of both the
                      platform and its users.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Index;
