import React from "react";
const Index = (initialWishlistData) => {
  return (
    <>
      <section className="howit-work space-cls">
        <div className="container">
          <div className="inner-title">
            <h3 className="heading-type2">Cancellation/Refund Policies</h3>
          </div>
          <div className="about-page">
            <div className="row">
              <div className="col-md-12">
                <div className="about-main-cont">
                  <div className="about-cont">
                    <p>
                      At Jewellskart.com, we strive to provide a seamless and
                      satisfying experience to our users. Our Cancellation and
                      Refund Policy outlines the guidelines for canceling orders
                      and processing refunds.
                    </p>
                    <h4>Cancellation Policy</h4>
                    <ul>
                      <li>
                        Retailers can request order cancellations before the
                        order is shipped. Once the order is shipped,
                        cancellation requests may not be accepted.
                      </li>
                      <li>
                        Retailers can return the products within 7 days of
                        products received. No questions asked.
                      </li>
                      <li>
                        Manufacturers are responsible for confirming and
                        processing cancellation requests promptly.
                      </li>
                    </ul>
                    <h4>Refund Policy</h4>
                    <ul>
                      <li>
                        Refunds will be processed for canceled orders, returned
                        products within 7 days, or orders with quality issues.
                      </li>
                      <li>
                        Retailers may be eligible for a refund if the product
                        received does not match the agreed-upon specifications
                        or is damaged.
                      </li>
                      <li>
                        Refunds will be issued to the original payment method
                        used for the transaction.
                      </li>
                      <li>
                        Refund processing times may vary depending on the
                        payment method and financial institution.
                      </li>
                    </ul>
                    <h4>Return Shipping</h4>
                    <ul>
                      <li>
                        For product returns, the cost of return shipping borne
                        by manufacturer, as agreed upon in the return policy.
                      </li>
                      <li>
                        Jewellskart.com will not be responsible for return
                        shipping costs unless explicitly stated in the return
                        policy.
                      </li>
                    </ul>
                    <h4>SHIPPING AND DELIVERY POLICY</h4>
                    <p>
                      At Jewellskart.com, we strive to ensure timely and secure
                      delivery of products to our valued users. Our Shipping and
                      Delivery Policy outlines the guidelines for shipping,
                      delivery, and associated terms.
                    </p>
                    <h4>Shipping Partners</h4>
                    <p>
                      We collaborate with reputable shipping partners to ensure
                      reliable and efficient delivery of products to your
                      specified location.
                    </p>
                    <h4>Shipping Fees</h4>
                    <p>
                      Shipping fees may vary based on the product's weight,
                      dimensions, delivery location, and chosen shipping method.
                      The shipping fees will be clearly communicated during the
                      checkout process.
                    </p>
                    <h4>Delivery Timeframes</h4>
                    <ul>
                      <li>
                        Delivery times may vary depending on the location and
                        shipping method chosen.
                      </li>
                      <li>
                        Estimated delivery times will be provided at the time of
                        purchase, but please note that they are approximate and
                        subject to change due to unforeseen circumstances.
                      </li>
                    </ul>
                    <h4>Shipping Updates</h4>
                    <p>
                      You will receive shipping updates and tracking information
                      for your order once it is dispatched.
                    </p>
                    <h4>Delivery Acceptance</h4>
                    <p>
                      Please ensure that you or a designated representative are
                      available to accept and sign for the delivery. Failure to
                      do so may result in delayed or returned shipments.
                    </p>
                    <h4>Damaged or Lost Shipments</h4>
                    <p>
                      In the event of a damaged or lost shipment, please contact
                      us immediately. We will work with our shipping partners to
                      resolve the issue promptly.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Index;
