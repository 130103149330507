import { Button, Col, Form, Input, Modal, Row, Typography } from "antd";
import React, { useContext, useState } from "react";
import { Severty, ShowToast } from "../helper/toast";
import useRequest from "../hooks/useRequest";
import { AuthContext } from "../context/AuthContext";
import PhoneInput from "react-phone-input-2";
const { Title } = Typography;

const RegisterModal = ({ open, setOpen }) => {
  // const [login, setLogin] = useState(false);

  const [form] = Form.useForm();

  const [mobileNumber, setMobileNumber] = useState({
    mobile: "",
    country_code: "",
  });
  const [loading, setLoading] = useState(false);
  const { request } = useRequest();
  const { setUserProfile } = useContext(AuthContext);

  const onSubmitRegister = (values) => {
    const { email, password, name } = values;

    if (!email)
      return ShowToast("Please enter email to sign in", Severty.ERROR);

    const payload = { password };
    if (!email) return ShowToast("Please enter valid email ", Severty.ERROR);

    setLoading(true);
    payload.email = email;
    payload.password = password;
    payload.mobile_number = mobileNumber.mobile;
    payload.country_code = mobileNumber.country_code;
    payload.name = name;
    request({
      url: "https://jewellskart.com/apanel/api/signUp_new",
      // url: "/auth/signUp",
      method: "POST",
      data: payload,
      onSuccess: (data) => {
        setLoading(false);
        console.log(data, "data.message");
        if (data.status) {
          console.log(data);
          handleClose();
          localStorage.setItem("token", data.data.api_token);
          localStorage.setItem("userProfile", JSON.stringify(data.data));
          ShowToast(data.message, Severty.SUCCESS);
          setUserProfile(data.data.user);
          window.location.reload();
        } else {
          ShowToast(data.errors, Severty.ERROR);
        }
      },
      onError: (error) => {
        ShowToast(error.response.data.message, Severty.ERROR);
        setLoading(false);
      },
    });
  };
  const handleChangeMobile = (value, data, event, formattedValue) => {
    var country_code = data.dialCode;
    setMobileNumber({
      country_code: country_code,
      mobile: value.slice(data.dialCode.length),
    });
  };
  const handleClose = () => {
    setOpen(false);
    form.resetFields();
    setMobileNumber({
      mobile: "",
      country_code: "",
    });
  };

  return (
    <div>
      <Modal
        className="sign-modal"
        open={open}
        footer={null}
        onCancel={handleClose}
      >
        <Row justify="space-around">
          <Col xs={{ span: 24 }} lg={{ span: 24 }} md={{ span: 24 }}>
            <div className="signup-form">
              <div className="modal-tital">
                <Title className="mb-15">Sign Up</Title>
                {/* <Title className="font-regular text-muted" level={5}>
                  Enter your email and password to sign in
                </Title> */}
              </div>
              <Form
                form={form}
                layout="vertical"
                className="row-col"
                onFinish={onSubmitRegister}
              >
                <Form.Item
                  className="username"
                  label="Name"
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: "Please enter your name!",
                    },
                  ]}
                >
                  <Input
                    className="sign-innn"
                    placeholder="Enter Email Address"
                  />
                </Form.Item>
                <Form.Item
                  className="username"
                  label="Email Address"
                  name="email"
                  rules={[
                    {
                      type: "email",
                      message: "Please enter a valid email address!",
                    },
                    {
                      max: 255,
                      message: "Email address not more then 255 characters!",
                    },
                    {
                      required: true,
                      message: "Please enter your email!",
                    },
                  ]}
                >
                  <Input
                    className="sign-innn"
                    placeholder="Enter Email Address"
                  />
                </Form.Item>
                <Form.Item label="Mobile Number">
                  <PhoneInput
                    className="username"
                    inputProps={{
                      name: "mobile",
                      required: true,
                      autoFocus: false,
                    }}
                    isValid={(value, country) => {
                      if (value.match(/1234/)) {
                        return "Invalid value: " + value + ", " + country.name;
                      } else if (value.match(/1234/)) {
                        return "Invalid value: " + value + ", " + country.name;
                      } else {
                        return true;
                      }
                    }}
                    country={"kw"}
                    value={
                      mobileNumber
                        ? (mobileNumber.country_code
                            ? mobileNumber.country_code
                            : "+91") +
                          (mobileNumber.mobile ? mobileNumber.mobile : null)
                        : ""
                    }
                    onChange={handleChangeMobile}
                  />
                </Form.Item>

                <Form.Item
                  className="password"
                  label="Password"
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "Please enter your password!",
                    },
                  ]}
                >
                  <Input.Password
                    className="sign-innn"
                    autoComplete="off"
                    placeholder="Enter Password"
                  />
                </Form.Item>
                <Form.Item
                  className="password"
                  label="Confirm Password"
                  name="confirm_password"
                  dependencies={["password"]}
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message: "Please input the confirm password!",
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue("password") === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error("Passwords that you entered do not match!")
                        );
                      },
                    }),
                  ]}
                >
                  <Input.Password 
                       className="sign-innn"
                      //  autoComplete="off"
                       placeholder="Confirm Password" />
                </Form.Item>

                <Form.Item>
                  <Button className="sign-btn" type="primary" htmlType="submit">
                    {" "}
                    SIGN UP{" "}
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </Col>
        </Row>
      </Modal>
    </div>
  );
};

export default RegisterModal;
