import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom";
import { AuthProvider } from "./context/AuthContext";
import { AppContextProvider } from "./context/AppContext";
import React, { lazy, Suspense } from "react";
import ScrollToTop from "./components/ScrollToTop";
import Loader from "./components/Loader";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./assets/styles/main.css";
import "./assets/styles/responsive.css";
import LoginModal from "./components/LoginModal.jsx";
import Categories from "./pages/Categories";
import Addtocart from "./pages/AddToCart";
import ProductDetail from "./pages/ProductDetail";
import Wishlist from "./pages/Wishlist";
import HomePage from "./pages/HomePage";
import Account from "./pages/Account";
import About from "./pages/About.js";
import TermsConditions from "./pages/TermsConditions.js";
import PoliciesIndex from "./pages/PoliciesIndex.js";
import PrivacyIndex from "./pages/PrivacyPolicy.js";
import PreOrder from "./pages/PreOrder.js";
const Header = lazy(() => import("./components/layout/Header"));

window.Buffer = window.Buffer || require("buffer").Buffer;
function App() {
  return (
    <AuthProvider>
      <AppContextProvider>
        <Suspense fallback={<Loader />}>
          <BrowserRouter>
            <ScrollToTop />
            <ToastContainer closeOnClick={false} />
            <AppRoutes />
          </BrowserRouter>
        </Suspense>
      </AppContextProvider>
    </AuthProvider>
  );
}

const AppRoutes = () => {
  return (
    <>
      {" "}
      <Suspense fallback={<div>Loading...</div>}>
        <Header />
      </Suspense>
      <Routes>
        {/* <Route path="/Home" element={<IndexHome />} /> */}

        <Route path="/" element={<HomePage />} />
        <Route
          path="/product/:type/:id"
          element={<Categories />}
        />
        <Route path="/pre_order" element={<PreOrder />} />
        <Route path="/add-to-cart" element={<Addtocart />} />
        <Route
          path="/product-detail/:id"
          element={<ProductDetail />}
        />
        {/* <Route path="/Home" element={<IndexHome />} /> */}
        <Route path="/wishlist" element={<Wishlist />} />
        <Route path="/login" element={<LoginModal />} />
        <Route path="/account" element={<Account />} />
        <Route path="/about" element={<About />} />
        <Route
          path="/terms-conditions"
          element={<TermsConditions />}
        />
        <Route
          path="/policies"
          element={<PoliciesIndex />}
        />
        <Route
          path="/privacy-policy"
          element={<PrivacyIndex />}
        />

        {/* <Route path="/  " element={<HomePage />} />
      <Route path="/category" element={<Categories />} />
      <Route path="/add-to-cart" element={<Addtocart />} />
      <Route path="/product-detail" element={<ProductDetail />} />
      <Route path="/wishlist" element={<Wishlist />} /> */}
      </Routes>
    </>
  );
};

const Layout = () => {
  return (
    <>
      {" "}
      <Outlet />{" "}
    </>
  );
};

export default App;
