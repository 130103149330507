import React, { useEffect, useState, useContext } from "react";
import { Row, Col, Input } from "antd";
import $ from "jquery";

import { StarFilled, StarOutlined } from "@ant-design/icons";

import Footer from "../components/layout/Footer";
import Accordion from "react-bootstrap/Accordion";

import PinterestIcon from "../assets/images/icon/pinterest.svg";
import FacebookIcon from "../assets/images/icon/facebook.svg";
import TwitterIcon from "../assets/images/icon/twitter.svg";
import FacebookIcon01 from "../assets/images/facebook.svg";
import InstagramIcon01 from "../assets/images/instagram.svg";
import PinterestIcon01 from "../assets/images/pinterest.svg";
import Product01 from "../assets/images/product01.png";
import OwlCarousel from "react-owl-carousel3";
import { useParams } from "react-router";
import useRequest from "../hooks/useRequest";
import { Severty, ShowToast } from "../helper/toast";
import { Container } from "react-bootstrap";
import { AuthContext } from "../context/AuthContext";
import { useNavigate } from "react-router";
import ReactImageMagnify from "react-image-magnify";

const ProductDetailIndex = () => {
  const [productData, setProductData] = useState([]);
  const [productDataRelated, setProductDataRelated] = useState([]);
  const [imageData, setImageData] = useState([]);
  const [loading, setLoading] = useState(false);
  const { request } = useRequest();
  const params = useParams();
  const { setCartItemCount } = useContext(AuthContext);
  const [login, setLogin] = useState();
  const navigate = useNavigate();
  const [quantity, setQuantity] = useState(1);

  var thumbs = $(".img-selection").find("img");

  thumbs.click(function () {
    var src = $(this).attr("src");
    var dp = $(".display-img");
    var img = $(".zoom");
    dp.attr("src", src);
    img.attr("src", src);
  });

  $(".img-thumbnail").click(function () {
    $(".img-thumbnail").removeClass("selected");
    $(this).addClass("selected");
  });

  var zoom = $(".big-img").find("img").attr("src");
  $(".big-img").append('<img className="zoom" src="' + zoom + '">');
  $(".big-img").mouseenter(function () {
    $(this).mousemove(function (event) {
      var offset = $(this).offset();
      var left = event.pageX - offset.left;
      var top = event.pageY - offset.top;

      $(this).find(".zoom").css({
        width: "200%",
        opacity: 1,
        left: -left,
        top: -top,
      });
    });
  });

  $(".big-img").mouseleave(function () {
    $(this).find(".zoom").css({
      width: "100%",
      opacity: 0,
      left: 0,
      top: 0,
    });
  });
  useEffect(() => {
    if (!params.id) return;
    getProduct(params.id);
  }, [params]);

  const convertImageStringToArray = (imageString) => {
    return imageString.split(",").filter((image) => image !== "");
  };

  useEffect(() => {
    setLoading(true);
    request({
      url: `/common/getRelatedProduct/${params.id}`,
      method: "GET",
      onSuccess: (data) => {
        console.log(data.data);
        setLoading(false);
        // ShowToast(data.message, Severty.SUCCESS)
        setProductDataRelated(data.data.resultNew);
      },
      onError: (error) => {
        console.log(error);
        ShowToast(error, Severty.ERROR);
      },
    });
  }, []);
  const getProduct = (id) => {
    setLoading(true);
    request({
      url: `/common/product-details/${id}`,
      method: "GET",
      onSuccess: (data) => {
        setProductData(data?.data || {});
        const imageArray = convertImageStringToArray(
          data?.data.product_image || ","
        );

        console.log(imageArray, "imageArray");
        setImageData(imageArray);

        setLoading(false);
      },
      onError: (error) => {
        console.log(error);
        ShowToast(error, Severty.ERROR);
      },
    });
  };

  const buyNow = () => {
    navigate("/add-to-cart");
  };
  const handleDecrement = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1);
    }
  };

  const handleIncrement = () => {
    setQuantity(quantity + 1);
  };

  const addToCart = (id) => {
    const isLoggedIn = localStorage.getItem("token");
    if (!isLoggedIn) {
      setLogin(() => true);
    } else {
      // setLoading(true);

      request({
        url: `/shopping/cart?productId=${id}`,
        method: `POST`,
        onSuccess: (data) => {
          // setLoading(false);
          if (data.status) {
            setProductData((prev) => ({
              ...prev,
              addInCard: !prev.addInCard,
            }));
            setCartItemCount((prev) => prev + 1);
            ShowToast(data.message, Severty.SUCCESS);
          } else {
            ShowToast(data.message, Severty.ERROR);
          }
        },
        onError: (error) => {
          ShowToast(error.response.data.message, Severty.ERROR);

          // setLoading(false);
        },
      });
    }
  };

  const [selectedImage, setSelectedImage] = useState("");

  useEffect(() => {
    if (imageData.length > 0) {
      setSelectedImage(imageData[0]);
    }
  }, [imageData]);

  const handleThumbnailClick = (image) => {
    setSelectedImage(image);
  };

  const responsiveSetting = {
    0: {
      items: 1,
    },
    300: {
      items: 1,
    },
    400: {
      items: 2,
    },
    600: {
      items: 2,
    },
    800: {
      items: 3,
    },
    1200: {
      items: 4,
    },
    1400: {
      items: 4,
    },
  };
  const handleEyeClick = (id) => {
    navigate(`/product-detail/${id}`);
  };
  return (
    <>
      <div className="product-main-d">
        <Container>
          <div className="main-outer">
            <div className="product-detail-section">
              <Row>
                <Col span={24} md={12}>
                  <div className="wrapper">
                    <div className=" zommer">
                      {selectedImage && (
                        <ReactImageMagnify
                          {...{
                            smallImage: {
                              alt: "Wristwatch by Ted Baker London",
                              isFluidWidth: true,
                              src: `https://jewellskart.com/apanel/public/upload/product_image/${selectedImage}`,
                            },
                            largeImage: {
                              src: `https://jewellskart.com/apanel/public/upload/product_image/${selectedImage}`,
                              width: 1139,
                              height: 1800,
                            },
                            lensStyle: { backgroundColor: "rgba(0,0,0,.6)" }, // Optional: Lens style
                          }}
                        />
                      )}
                    </div>
                    <div className="img-selection">
                      {imageData.length > 0 &&
                        imageData.map((item, index) => (
                          <div
                            key={index}
                            className={`img-thumbnail ${selectedImage === item ? "selected" : ""
                              }`}
                            onClick={() => handleThumbnailClick(item)}
                          >
                            <img
                              src={`https://jewellskart.com/apanel/public/upload/product_image/${item}`}
                              width="100%"
                              alt={`Thumbnail ${index}`}
                            />
                          </div>
                        ))}
                    </div>
                  </div>
                </Col>
                <Col span={24} md={12}>
                  <div className="product-info ">
                    <h1 className="title">
                      {productData ? productData.product_name : ""}
                    </h1>
                    <div className="ratting-star justify-content-start">
                      <span>
                        <StarFilled />
                      </span>
                      <span>
                        <StarFilled />
                      </span>
                      <span>
                        <StarFilled />
                      </span>
                      <span>
                        <StarFilled />
                      </span>
                      <span>
                        <StarOutlined />
                      </span>
                      <span>150 Reviews</span>
                    </div>
                    {productData.discount > 0 ? (
                      <span className="price">
                        <ins>
                          <span>{productData && productData.offer_price}</span>
                        </ins>
                        <del aria-hidden="true">
                          <span>{productData && productData.total_price}</span>
                        </del>
                      </span>
                    ) : (
                      <span className="price">
                        <ins>
                          <span>{productData && productData.total_price}</span>
                        </ins>
                      </span>
                    )}
                    <div className="description">
                      <p
                        dangerouslySetInnerHTML={{
                          __html: productData ? productData.description : "--",
                        }}
                      ></p>
                    </div>
                    <div className="qa-main">
                      <div className="quantity">
                        <label htmlFor="qty">QTY</label>
                        <div className="qty-controls">
                          <button onClick={handleDecrement}>-</button>
                          <input
                            type="text"
                            id="qty"
                            value={quantity}
                            readOnly
                          />
                          <button onClick={handleIncrement}>+</button>
                        </div>
                      </div>
                    </div>
                    <div className="dics-ass">
                      {productData &&
                        productData.product_type_id == "in-stock" ? (
                        <p>
                          {" "}
                          This product is available, We can delivery within{" "}
                          {productData.delivery_time} days.
                        </p>
                      ) : productData &&
                        productData.product_type_id == "re-order" ? (
                        <p>
                          This product is out of stock, We can re make and
                          delivers within {productData.delivery_time} days.
                        </p>
                      ) : (
                        <p>
                          This product is in design format only, We can delivery
                          within {productData.delivery_time} days.
                        </p>
                      )}
                    </div>
                    <Accordion className="accordion-outer" defaultActiveKey="0">
                      <Accordion.Item className="main-acco" eventKey="0">
                        <Accordion.Header className="headding-acco">
                          Customization
                        </Accordion.Header>
                        <Accordion.Body>
                          <div className="inn-input">
                            <Input
                              className="sign-innn"
                              placeholder="Customization"
                            />
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                    <div className="buttons">
                      <div className="add-to-cart-wrap">
                        <div className="btn-add-to-cart">
                          <a
                            href="#"
                            tabIndex={0}
                            onClick={() => addToCart(productData.id)}
                          >
                            Add to cart
                          </a>
                        </div>
                      </div>
                      <div className="btn-quick-buy" data-title="Wishlist">
                        <button className="product-btn" onClick={buyNow}>
                          Buy Now
                        </button>
                      </div>
                    </div>
                    <div class="product-meta">
                      <span class="sku-wrapper">
                        SKU:{" "}
                        <span class="sku">
                          {productData && productData.sku}
                        </span>
                      </span>
                      <span class="tagged-as">
                        Availablity:{" "}
                        <span class="sku">
                          {productData && productData.product_type_id}
                        </span>
                      </span>
                      <span class="tagged-as">
                        Finish Type:{" "}
                        <span class="sku">
                          {productData ? productData.finish_type : "--"}
                        </span>
                      </span>
                      <span class="posted-in">
                        Gross Weight (Gm):{" "}
                        <span class="sku">
                          {productData ? productData.gross_weight_gram : "--"}
                        </span>
                      </span>
                      {/* <span class="posted-in">
                        Availablity:{" "}
                        <span class="sku">
                          {productData && productData.product_type_id}
                        </span>
                      </span> */}
                      <span class="tagged-as">
                        Net Weight (Gm):{" "}
                        <span class="sku">
                          {productData ? productData.net_weight_gram : "--"}
                        </span>
                      </span>
                      <span class="tagged-as">
                        Piroi Price:{" "}
                        <span class="sku">
                          {productData ? productData.piroi_price : "--"}
                        </span>
                      </span>
                    </div>
                    <div className="social-share">
                      <a
                        href="#"
                        title="Facebook"
                        className="share-facebook"
                        target="_blank"
                      >
                        <img src={FacebookIcon} />
                        Facebook
                      </a>
                      <a href="#" title="Twitter" className="share-twitter">
                        <img src={TwitterIcon} />
                        Twitter
                      </a>
                      <a href="#" title="Pinterest" className="share-pinterest">
                        <img src={PinterestIcon} />
                        Pinterest
                      </a>
                    </div>
                  </div>
                </Col>
              </Row>
              {/* <div className="desk-detail">
                <Row>
                  <Col span={24}>
                    <Tabs
                      defaultActiveKey="1"
                      onChange={onChange}
                      className="pd-tab"
                    >
                      <TabPane
                        tab={<span>Description</span>}
                        className="product-detail-button"
                        key="1"
                      >
                        <div className="tabs-body-text">
                          <p
                            dangerouslySetInnerHTML={{
                              __html: productData
                                ? productData.description
                                : "--",
                            }}
                          ></p>
                        </div>
                      </TabPane>
                      <TabPane
                        tab={<span>Additional Information</span>}
                        key="2"
                      >
                        <div className="add-body-text">
                          <ul>
                            <li>
                              <h6>Category</h6>
                              <p>
                                {productData &&
                                  productData.category &&
                                  productData.category
                                    .map((category) => category.name)
                                    .join(", ")}
                              </p>
                            </li>
                            <li>
                              <h6>Sub Category</h6>
                              <p>
                                {productData &&
                                  productData.sub &&
                                  productData.sub
                                    .map((subcategory) => subcategory.name)
                                    .join(", ")}
                              </p>
                            </li>
                          </ul>
                        </div>
                      </TabPane>
                    </Tabs>
                  </Col>
                </Row>
              </div> */}
              <div className="trending-section">
                <div className="headding-detail">
                  <h4>Related Product</h4>
                </div>
                <Container>
                  <Row>
                    <Col span={24}>
                      <OwlCarousel
                        className="owl-theme  category-section"
                        items={5}
                        loop
                        margin={26}
                        nav
                        responsive={{
                          0: {
                            items: 1,
                          },
                          600: {
                            items: 3,
                          },
                          1000: {
                            items: 3,
                          },
                          1200: {
                            items: 4,
                          },
                          1400: {
                            items: 5,
                          },
                        }}
                        dots={false}
                      >
                        {productDataRelated.length > 0 &&
                          productDataRelated.map((data) => (
                            <div
                              className="item"
                              onClick={() => handleEyeClick(data.id)}
                            >
                              <div className="products-items-list">
                                <div className="pruducts-items">
                                  {data.product_image ? (
                                    <img
                                      src={`https://jewellskart.com/apanel/public/upload/product_image/${data.product_image.split(",")[0]
                                        }`}
                                      alt="img"
                                    />
                                  ) : (
                                    <img src={Product01} />
                                  )}
                                  {/* <div className="bg-icon"><HeartOutlined /></div> */}
                                  <ul className="d-flex align-items-center justify-content-center list-unstyled icons">
                                    {/* <a href={`/product-detail/${data.id}`}> */}

                                    <li
                                      className="icon"
                                      onClick={() => handleEyeClick(data.id)}
                                    >
                                      <span className="fas fa-eye"></span>
                                    </li>
                                    {/* </a> */}
                                    <li
                                      className="icon mx-3"
                                    // onClick={() => addWishList(data.id)}
                                    >
                                      {data.isFavorite ? (
                                        <span class="fa fa-heart"></span>
                                      ) : (
                                        <span class="far fa-heart"></span>
                                      )}
                                    </li>
                                    <li
                                      class="icon"
                                      onClick={() => addToCart(data.id)}
                                    >
                                      {data.addInCard ? (
                                        <i
                                          class="fa fa-shopping-bag"
                                          aria-hidden="true"
                                        ></i>
                                      ) : (
                                        <i
                                          class="fa fa-shopping-bag"
                                          aria-hidden="true"
                                        ></i>
                                      )}
                                    </li>
                                  </ul>
                                </div>
                                <div className="product-detail">
                                  <h6>{data.product_name}</h6>
                                  <div className="ratting-star">
                                    <span>
                                      <StarFilled />
                                    </span>
                                    <span>
                                      <StarFilled />
                                    </span>
                                    <span>
                                      <StarFilled />
                                    </span>
                                    <span>
                                      <StarFilled />
                                    </span>
                                    <span>
                                      <StarOutlined />
                                    </span>
                                  </div>

                                  {data.discount > 0 ? (
                                    <p className="price-ff">
                                      <span>Rs {data.total_price}</span> RS{" "}
                                      {data.offer_price}
                                    </p>
                                  ) : (
                                    <p className="price-ff">
                                      {data.total_price}
                                    </p>
                                  )}

                                  {/* <p className="price-ff">
                            <span>Rs {data.base_price_per_gram}</span>RS{" "}
                            {data.piroi_price}
                          </p> */}

                                  {/* {isLoggedIn ? <Button onClick={() => setCartData(true)} className="cart-btn">Add to cart</Button> : setSignUpModal(true)} */}
                                </div>
                              </div>
                            </div>
                          ))}
                      </OwlCarousel>
                    </Col>
                  </Row>
                </Container>
              </div>
            </div>
          </div>
        </Container>
      </div>

      <Footer>
        <Container>
          <Row>
            <Col span={12} md={6}>
              <div className="footer-main">
                <h4>Information</h4>
                <ul>
                  <li>
                    <a href="#">Blog</a>
                  </li>
                  <li>
                    <a href="#">Offers Details</a>
                  </li>
                  <li>
                    <a href="#">Privacy policy</a>
                  </li>
                  <li>
                    <a href="#">About Us</a>
                  </li>
                  <li>
                    <a href="#">terms and conditions</a>
                  </li>
                </ul>
              </div>
            </Col>
            <Col span={12} md={6}>
              <div className="footer-main">
                <h4>ORDER</h4>
                <ul>
                  <li>
                    <a href="#">My Account</a>
                  </li>
                  <li>
                    <a href="#">Log in</a>
                  </li>
                  <li>
                    <a href="#">My Addresses</a>
                  </li>
                  <li>
                    <a href="#">My Orders</a>
                  </li>
                </ul>
              </div>
            </Col>
            <Col span={12} md={6}>
              <div className="footer-main">
                <h4>FOLLOW US</h4>
                <ul>
                  <li>
                    <a href="#">
                      <img src={FacebookIcon01} />
                      Facebook
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <img src={InstagramIcon01} />
                      Instagram
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <img src={PinterestIcon01} />
                      Pinterest
                    </a>
                  </li>
                </ul>
              </div>
            </Col>
            <Col span={12} md={6}>
              <div className="footer-main">
                <h4>Contact Us</h4>
                <ul>
                  <li>
                    <a href="#">
                      <img src={FacebookIcon01} />
                      Mail to Us
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <img src={InstagramIcon01} />
                      +91-9204824823
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <img src={PinterestIcon01} />
                      Chat to Us
                    </a>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
      </Footer>
    </>
  );
};

export default ProductDetailIndex;
