import {
  Button,
  Row,
  Col,
  Typography,
  Form,
  Input,
  Modal,
  Drawer,
  Select,
  Alert,
} from "antd";

import React, {
  useContext,
  useEffect,
  useState,
  useCallback,
  useRef,
} from "react";

import { Container } from "react-bootstrap";

import { StarFilled, StarOutlined } from "@ant-design/icons";

import FilterIcon from "../assets/images/filter.svg";

import Product01 from "../assets/images/product01.png";
import useRequest from "../hooks/useRequest";
import { Severty, ShowToast } from "../helper/toast";
import { useParams } from "react-router";
import { AuthContext } from "../context/AuthContext";
import InfiniteScroll from "react-infinite-scroll-component";
import { useNavigate } from "react-router-dom";
import LoginModal from "../components/LoginModal";
import Loader from "./Loader";
import PhoneInput from "react-phone-input-2";
import { LazyLoadImage } from "react-lazy-load-image-component";
import ImagePlaceholder from "../assets/images/ImagePlaceholder.png";

const { Title } = Typography;
const { Option } = Select;

const CategoriesIndex = () => {
  const isLoggedIn = localStorage.getItem("token");
  const { request } = useRequest();
  const { setCartItemCount, setWishItemCount } = useContext(AuthContext);
  const [mainCategoryData, setMainCategoryData] = useState([]);
  const [allCategoryFilter, setAllCategoryFilter] = useState([]);
  const [allSubCategoryFilter, setAllSubCategoryFilter] = useState([]);
  const [productData, setProductData] = useState([]);
  const [products, setProducts] = useState(productData);
  const [loading, setLoading] = useState(true);
  const [currPage, setCurrPage] = useState(1);
  const params = useParams();
  const [hasMore, setHasMore] = useState(true);
  const navigate = useNavigate();
  const [login, setLogin] = useState();
  const { setUserProfile } = useContext(AuthContext);

  const [filters, setFilters] = useState({
    category: undefined,
    subcategory: undefined,
    sort: undefined,
    polish_id: undefined,
    product_type_id: undefined,
    jewelly: undefined,
    limit: 10,
    page: 1,
  });
  useEffect(() => {
    fetchProducts();
  }, [filters, currPage]);
  useEffect(() => {
    fetchCategories();
  }, []);

  useEffect(() => {
    // alert(params.type)
    if (params.type == "category") {
      setFilters((prev) => ({ ...prev, category: params.id }));
    }
    if (params.type == "jewelly") {
      setFilters((prev) => ({ ...prev, jewelly: params.id }));
    } else {
      setFilters((prev) => ({ ...prev, subcategory: params.id }));
    }
  }, [params]);

  const fetchCategories = async () => {
    // setLoading(true);
    request({
      url: "/common/getAllCategory",
      method: "GET",
      params: { sort: filters.sort },
      onSuccess: (data) => {
        console.log(data.data);
        // setLoading(false);
        // ShowToast(data.message, Severty.SUCCESS)
        setMainCategoryData(data.data.data);
      },
      onError: (error) => {
        setLoading(false);
        console.log(error);
        ShowToast(error, Severty.ERROR);
      },
    });
  };

  const fetchProducts = async () => {
    try {
      const queryParams = Object.entries(filters)
        .filter(([_, v]) => v)
        .map(
          ([key, value]) =>
            `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
        )
        .join("&");

      // setLoading(true);
      request({
        url: `/common/getProduct?${queryParams}`,
        method: "GET",
        onSuccess: (data) => {
          if (currPage === 1) {
            setProductData(data.data.data); // Reset product data if on the first page
          } else {
            setProductData((prevData) => [...prevData, ...data.data.data]); // Append data if not on the first page
          }
          setHasMore(data.data.data.length > 0);
          // setLoading(false);
        },
        onError: (error) => {
          console.log(error);
          ShowToast(error, Severty.ERROR);
          setLoading(false);
        },
      });
    } catch (error) {
      setLoading(false);
      ShowToast(error, Severty.ERROR);
    }
  };

  const handleChangeCategory = async (value) => {
    // setLoading(true);

    request({
      url: `/common/getAllSubCategoryFilter/${value}`,
      method: "GET",
      onSuccess: (data) => {
        console.log(data.data);
        // ShowToast(data.message, Severty.SUCCESS)
        setAllSubCategoryFilter(data.data.data);
        setFilters((prev) => ({ ...prev, category: value }));
        // setLoading(false);
      },
      onError: (err) => {
        // setLoading(false);
        console.log(err);
      },
    });
    const { data } = await request({
      url: `/common/getAllSubCategoryFilter/${value}`,
      method: "GET",
    });
  };
  useEffect(() => {
    setLoading(true);
    request({
      url: "/common/getAllCategoryFilter",
      method: "GET",
      onSuccess: (data) => {
        console.log(data.data);
        setLoading(false);
        // ShowToast(data.message, Severty.SUCCESS)
        setAllCategoryFilter(data.data.data);
      },
      onError: (error) => {
        setLoading(false);
        console.log(error);
        ShowToast(error, Severty.ERROR);
      },
    });
  }, []);

  const addWishList = (id) => {
    console.log(id);
    const isLoggedIn = localStorage.getItem("token");
    if (!isLoggedIn) {
      setLogin(() => true);
    } else {
      // setLoading(true);

      request({
        url: `/shopping/favorite?productId=${id}`,
        method: `GET`,
        onSuccess: (data) => {
          // setLoading(false);
          if (data.status) {
            ShowToast(data.message, Severty.SUCCESS);
            setProductData((prev) =>
              prev.map((item) => {
                if (item.id === id) {
                  setWishItemCount((prev) => {
                    console.log(
                      !item.isFavorite ? Number(prev) + 1 : Number(prev) - 1,
                      prev
                    );
                    return !item.isFavorite
                      ? Number(prev) + 1
                      : Number(prev) - 1;
                  });
                  return { ...item, isFavorite: !item.isFavorite };
                }
                return item;
              })
            );
          } else {
            ShowToast(data.message, Severty.ERROR);
          }
        },
        onError: (error) => {
          ShowToast(error.response.data.message, Severty.ERROR);

          setLoading(false);
        },
      });
    }
  };

  const addToCart = (id) => {
    const isLoggedIn = localStorage.getItem("token");
    if (!isLoggedIn) {
      setLogin(() => true);
    } else {
      // setLoading(true);

      request({
        url: `/shopping/cart?productId=${id}`,
        method: `POST`,
        onSuccess: (data) => {
          // setLoading(false);
          if (data.status) {
            setProductData((prev) =>
              prev.map((item) => {
                if (item.id === id) {
                  setCartItemCount((prev) => {
                    console.log(
                      !item.addInCard ? Number(prev) + 1 : Number(prev) - 1,
                      prev
                    );
                    return !item.addInCard
                      ? Number(prev) + 1
                      : Number(prev) - 1;
                  });
                  return { ...item, addInCard: !item.addInCard };
                }
                return item;
              })
            );
            ShowToast(data.message, Severty.SUCCESS);
          } else {
            ShowToast(data.message, Severty.ERROR);
          }
        },
        onError: (error) => {
          ShowToast(error.response.data.message, Severty.ERROR);

          // setLoading(false);
        },
      });
    }
  };

  const onChangeFilter = (type, id) => {
    setFilters((prev) => ({ ...prev, subcategory: id }));
  };

  const responsiveSetting = {
    0: {
      items: 1,
    },
    300: {
      items: 3,
    },
    400: {
      items: 3,
    },
    600: {
      items: 3,
    },
    800: {
      items: 4,
    },
    1000: {
      items: 5,
    },
    1200: {
      items: 6,
    },
    1400: {
      items: 6,
    },
  };
  const fetchMoreData = useCallback(async () => {
    console.log(currPage, "Fetching more data...");
    // Your data fetching logic for more data based on currPage can go here
  }, [currPage]);

  const handleEyeClick = (id) => {
    const isLoggedIn = localStorage.getItem("token");
    if (!isLoggedIn) {
      setIsModalVisible(true);
    } else {
      navigate(`/product-detail/${id}`);
    }
  };

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    mobile: "",
    designation: "",
    store: "",
    address: "",
    email: "",
    gst: "",
    password: "",
  });

  const [form] = Form.useForm();

  const [mobileNumber, setMobileNumber] = useState({
    mobile: "",
    country_code: "",
  });

  const handleSubmit = (values, id) => {
    const { email, password, name, gst, address, store, designation } = values;

    if (!email)
      return ShowToast("Please enter email to sign in", Severty.ERROR);

    const payload = { password };
    if (!email) return ShowToast("Please enter valid email ", Severty.ERROR);

    // setLoading(true);
    payload.email = email;
    payload.password = password;
    payload.mobile_number = mobileNumber.mobile;
    payload.country_code = mobileNumber.country_code;
    payload.name = name;
    payload.fullname = name;
    payload.gst = gst;
    payload.address = address;
    payload.store = store;
    payload.designation = designation;
    payload.otp = "1234";

    request({
      url: "https://jewellskart.com/apanel/api/signUp_new",
      method: "POST",
      data: payload,
      onSuccess: (data) => {
        setLoading(false);
        console.log(data, "data.message");
        if (data.status) {
          console.log(data);
          handleClose();
          localStorage.setItem("token", data.api_token);
          localStorage.setItem("userProfile", JSON.stringify(data.data));
          ShowToast(data.message, Severty.SUCCESS);
          setUserProfile(data.data.user);
          window.location.reload();
        } else {
          ShowToast(data.errors, Severty.ERROR);
          navigate(`/product-detail/${id}`);
        }
      },
      onError: (error) => {
        const errorMessage =
          error.response?.data?.message ||
          "An error occurred. Please try again.";
        ShowToast(errorMessage, Severty.ERROR);
        setLoading(false);
      },
    });
  };
  const handleError = (e) => {
    e.target.src = ImagePlaceholder;
  };
  const handleFilterChange = (key, value) => {
    setFilters((prev) => ({ ...prev, [key]: value }));
  };
  const loadPage = () => {
    setCurrPage((prev) => prev + 1);
    setFilters((prev) => ({ ...prev, page: prev.page + 1 }));
  };

  const handleChangeMobile = (value, data, event, formattedValue) => {
    var country_code = data.dialCode;
    setMobileNumber({
      country_code: country_code,
      mobile: value.slice(data.dialCode.length),
    });
  };
  const validateGST = (rule, value) => {
    // GST regex for basic format check
    const gstRegex = /^\d{2}[A-Z]{5}\d{4}[A-Z]{1}[1-9A-Z]{1}Z[A-Z\d]{1}$/;

    if (!value) {
      return Promise.reject("Please enter your GST number!");
    }

    if (!gstRegex.test(value)) {
      return Promise.reject("Invalid GST number format");
    }

    // Add more complex validation logic here if needed, such as checksum verification

    return Promise.resolve();
  };

  const handleClose = () => {
    setOpen(false);
    form.resetFields();
    setMobileNumber({
      mobile: "",
      country_code: "",
    });
  };
  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  const listRef = useRef();
  useEffect(() => {
    listRef.current?.lastElementChild?.scrollIntoView();
  }, [productData]);
  useEffect(() => {
    setFilters({ product_type_id: "pre-order" });
  }, []);

  return (
    <>
      <InfiniteScroll
        dataLength={productData.length}
        next={fetchMoreData}
        hasMore={true}
        // loader={<h1>loawwwwwder..</h1>}
        endMessage={<p>Yay! You have seen it all</p>}
      >
        {loading ? (
          <Loader />
        ) : (
          <Container>
            <div className="main-outer">
              <Row gutter={32}>
                <Col
                  className="d-none d-lg-block"
                  span={24}
                  lg={6}
                  xl={6}
                  xxl={6}
                >
                  <div className="filter-wrap ">
                    <div className="filter-short-by pt-3">
                      <h6>
                        <LazyLoadImage onError={handleError} src={FilterIcon} />
                        Filters
                      </h6>
                      <Form layout="vertical" className="row-col">
                        <Form.Item label="Short">
                          <Select
                            className="select-filtter"
                            defaultValue="Select by"
                            onChange={(value) =>
                              handleFilterChange("sort", value)
                            }
                          >
                            <Option value="High_to_Low">
                              Price: High To Low
                            </Option>
                            <Option value="Low_to_High">
                              Price : Low To High
                            </Option>
                            <Option value="Newest_to_Old">Newest To Old</Option>
                            <Option value="Old_to_Newest">
                              Oldest To Newest
                            </Option>
                          </Select>
                        </Form.Item>
                        <Form.Item label="Availability">
                          <Select
                            className="select-filtter"
                            defaultValue="Select by"
                            onChange={(value) =>
                              handleFilterChange("product_type_id", value)
                            }
                          >
                            <Option value="in-stock">In Stock</Option>
                            <Option value="re-order">Re Order</Option>
                            <Option value="pre-order">Pre Order</Option>
                          </Select>
                        </Form.Item>
                        <Form.Item label="Finish">
                          <Select
                            className="select-filtter"
                            defaultValue="Select by"
                            onChange={(value) =>
                              handleFilterChange("polish_id", value)
                            }
                          >
                            <Option value="22">Yellow Gold</Option>
                            <Option value="23">Antique Victorian</Option>
                          </Select>
                        </Form.Item>

                        <Form.Item label="Categories">
                          <Select
                            className="select-filtter"
                            defaultValue="Select by"
                            onChange={handleChangeCategory}
                          >
                            {allCategoryFilter &&
                              allCategoryFilter.length > 0 &&
                              allCategoryFilter.map((item) => (
                                <Option key={item.id} value={item.id}>
                                  <span className="cap">{item.name}</span>
                                </Option>
                              ))}
                          </Select>
                        </Form.Item>
                        <Form.Item label="Subcategories">
                          <Select
                            className="select-filtter"
                            defaultValue="Select by"
                            onChange={(e) => onChangeFilter("sub", e)}
                          >
                            {allSubCategoryFilter &&
                              allSubCategoryFilter.length > 0 &&
                              allSubCategoryFilter.map((item) => (
                                <Option key={item.id} value={item.id}>
                                  <span className="cap">{item.name}</span>
                                </Option>
                              ))}
                          </Select>
                        </Form.Item>
                      </Form>
                    </div>
                  </div>
                </Col>
                <Drawer title="Basic Drawer" onClose={onClose} open={open}>
                  <div className="filter-wrap ">
                    <div className="filter-short-by pt-3">
                      <h6>
                        <LazyLoadImage onError={handleError} src={FilterIcon} />
                        Filters
                      </h6>
                      <Form layout="vertical" className="row-col">
                        <Form.Item label="Short">
                          <Select
                            className="select-filtter"
                            defaultValue="Select by"
                            onChange={(value) =>
                              handleFilterChange("sort", value)
                            }
                          >
                            <Option value="High_to_Low">
                              Price: High To Low
                            </Option>
                            <Option value="Low_to_High">
                              Price : Low To High
                            </Option>
                            <Option value="Newest_to_Old">Newest To Old</Option>
                            <Option value="Old_to_Newest">
                              Oldest To Newest
                            </Option>
                          </Select>
                        </Form.Item>
                        <Form.Item label="Availability">
                          <Select
                            className="select-filtter"
                            defaultValue="Select by"
                            onChange={(value) =>
                              handleFilterChange("product_type_id", value)
                            }
                          >
                            <Option value="in-stock">In Stock</Option>
                            <Option value="re-order">Re Order</Option>
                            <Option value="pre-order">Pre Order</Option>
                          </Select>
                        </Form.Item>
                        <Form.Item label="Finish">
                          <Select
                            className="select-filtter"
                            defaultValue="Select by"
                            onChange={(value) =>
                              handleFilterChange("polish_id", value)
                            }
                          >
                            <Option value="22">Yellow Gold</Option>
                            <Option value="23">Antique Victorian</Option>
                          </Select>
                        </Form.Item>
                        <Form.Item label="Categories">
                          <Select
                            className="select-filtter"
                            defaultValue="Select by"
                            onChange={handleChangeCategory}
                          >
                            {allCategoryFilter &&
                              allCategoryFilter.length > 0 &&
                              allCategoryFilter.map((item) => (
                                <Option key={item.id} value={item.id}>
                                  <span className="cap">{item.name}</span>
                                </Option>
                              ))}
                          </Select>
                        </Form.Item>
                        <Form.Item label="Subcategories">
                          <Select
                            className="select-filtter"
                            defaultValue="Select by"
                            onChange={(e) => onChangeFilter("sub", e)}
                          >
                            {allSubCategoryFilter &&
                              allSubCategoryFilter.length > 0 &&
                              allSubCategoryFilter.map((item) => (
                                <Option key={item.id} value={item.id}>
                                  <span className="cap">{item.name}</span>
                                </Option>
                              ))}
                          </Select>
                        </Form.Item>
                      </Form>
                    </div>
                  </div>
                </Drawer>
                <Col span={24} lg={18} xl={18} xxl={18}>
                  <Row>
                    <div className="filter_main">
                      <div className="filter_pop d-lg-none">
                        <a
                          className="filter_icon"
                          href="javascript:void(0);"
                          onClick={showDrawer}
                        >
                          <LazyLoadImage
                            onError={handleError}
                            src={FilterIcon}
                          />{" "}
                          Filter
                        </a>
                      </div>
                    </div>
                  </Row>
                  {productData.length > 0 ? (
                    <>
                      <div className="headding-cat">
                        <h3>Products</h3>
                      </div>
                      <Row className="" ref={listRef}>
                        {productData.map((data) => (
                          <React.Fragment key={data.unique_id}>
                            <Col span={24} sm={12} md={8} lg={6} xxl={4}>
                              <div
                                className="products-items-list"
                                onClick={() => handleEyeClick(data.id)}
                              >
                                <div className="pruducts-items">
                                  {data.product_image ? (
                                    <LazyLoadImage
                                      onError={handleError}
                                      src={`https://jewellskart.com/apanel/public/upload/product_image/${data.product_image.split(",")[0]
                                        }`}
                                    />
                                  ) : (
                                    <LazyLoadImage
                                      onError={handleError}
                                      src={Product01}
                                    />
                                  )}
                                  {/* <div className="bg-icon"><HeartOutlined /></div> */}
                                  <ul class="d-flex align-items-center justify-content-center list-unstyled icons">
                                    <li
                                      className="icon"
                                      onClick={() => handleEyeClick(data.id)}
                                    >
                                      <span className="fas fa-eye"></span>
                                    </li>

                                    <li
                                      onClick={() => addWishList(data.id)}
                                      class="icon mx-3"
                                    >
                                      {data.isFavorite ? (
                                        <span class="fa fa-heart"></span>
                                      ) : (
                                        <span class="far fa-heart"></span>
                                      )}
                                    </li>
                                    <li
                                      class="icon"
                                      onClick={() => addToCart(data.id)}
                                    >
                                      {data.addInCard ? (
                                        <span class="fa fa-shopping-bag"></span>
                                      ) : (
                                        <span class="fas fa-shopping-bag"></span>
                                      )}
                                    </li>
                                  </ul>
                                </div>
                                <div className="product-detail">
                                  <h6>{data.product_name}</h6>

                                  <div className="ratting-star">
                                    <span>
                                      <StarFilled />
                                    </span>
                                    <span>
                                      <StarFilled />
                                    </span>
                                    <span>
                                      <StarFilled />
                                    </span>
                                    <span>
                                      <StarFilled />
                                    </span>
                                    <span>
                                      <StarOutlined />
                                    </span>
                                  </div>
                                  {isLoggedIn ? (
                                    data.discount > 0 ? (
                                      <p className="price-ff">
                                        <span>Rs {data.total_price}</span> RS{" "}
                                        {data.offer_price}
                                      </p>
                                    ) : (
                                      <p className="price-ff">
                                        {data.total_price}
                                      </p>
                                    )
                                  ) : (
                                    <p className="price-ff">
                                      <span>
                                        Please log in to see the price
                                      </span>
                                    </p>
                                  )}
                                  {/* <Button className="cart-btn">Add to cart</Button> */}
                                </div>
                              </div>
                            </Col>
                          </React.Fragment>
                        ))}
                        <Col span={24}>
                          <Button onClick={loadPage}>Load More</Button>
                        </Col>
                      </Row>
                    </>
                  ) : (
                    <Alert message="Product Not Found" type="info" />
                  )}
                </Col>
              </Row>
            </div>
          </Container>
        )}
      </InfiniteScroll>

      <Modal
        className="sign-modal"
        // title="Sign Up"
        open={isModalVisible}
        footer={null}
        onOk={() => handleSubmit(formData)}
        onCancel={() => setIsModalVisible(false)}
      // okText="Save"
      // cancelText="Cancel"
      >
        <Row justify="space-around">
          <Col xs={{ span: 24 }} lg={{ span: 24 }} md={{ span: 24 }}>
            <div className="signup-form">
              <div className="modal-title">
                <Title className="mb-15" style={{ textAlign: "center" }}>
                  Save Your Data
                </Title>
              </div>
              <Form
                form={form}
                layout="vertical"
                className="row-col"
                onFinish={handleSubmit}
              >
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item
                      label="Name"
                      name="name"
                      rules={[
                        { required: true, message: "Please enter your name!" },
                      ]}
                    >
                      <Input className="sign-innn" />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label="Designation"
                      name="designation"
                      rules={[
                        {
                          required: true,
                          message: "Please enter your designation!",
                        },
                      ]}
                    >
                      <Input className="sign-innn" />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item
                      label="GST No"
                      name="gst"
                      rules={[
                        {
                          required: true,
                          validator: validateGST,
                        },
                      ]}
                    >
                      <Input className="sign-innn" />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label="Store Name"
                      name="store"
                      rules={[
                        {
                          required: true,
                          message: "Please enter your store name!",
                        },
                      ]}
                    >
                      <Input className="sign-innn" />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item
                      label="Address"
                      name="address"
                      rules={[
                        {
                          required: true,
                          message: "Please enter your address!",
                        },
                      ]}
                    >
                      <Input className="sign-innn" />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label="Email Address"
                      name="email"
                      rules={[
                        {
                          type: "email",
                          message: "Please enter a valid email address!",
                        },
                        { required: true, message: "Please enter your email!" },
                      ]}
                    >
                      <Input className="sign-innn" />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item label="Mobile Number">
                      <PhoneInput
                        className="username"
                        inputStyle={{ width: "230px", height: "45px" }}
                        inputProps={{
                          name: "mobile",
                          required: true,
                          autoFocus: false,
                        }}
                        isValid={(value, country) => {
                          if (value.match(/1234/)) {
                            return (
                              "Invalid value: " + value + ", " + country.name
                            );
                          } else if (value.match(/1234/)) {
                            return (
                              "Invalid value: " + value + ", " + country.name
                            );
                          } else {
                            return true;
                          }
                        }}
                        country={"kw"}
                        value={
                          mobileNumber
                            ? (mobileNumber.country_code
                              ? mobileNumber.country_code
                              : "+91") +
                            (mobileNumber.mobile ? mobileNumber.mobile : null)
                            : ""
                        }
                        onChange={handleChangeMobile}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label="Password"
                      name="password"
                      rules={[
                        {
                          required: true,
                          message: "Please enter your password!",
                        },
                      ]}
                    >
                      <Input.Password
                        className="sign-innn"
                        autoComplete="off"
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Form.Item>
                  <Button className="sign-btn" type="primary" htmlType="submit">
                    Save
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </Col>
        </Row>
      </Modal>
      <LoginModal open={login} setOpen={setLogin} />
    </>
  );
};

export default CategoriesIndex;
